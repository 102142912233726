import React from 'react'

function Methods() {
    return (
        <div className=" bg-white flex flex-col items-center justify-center text-white p-8">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
                <div className="bg-gray-800 p-6 rounded-lg">
                    <h2 className="text-2xl font-semibold">Email Us</h2>
                    <p className="mt-2">alex@ultrahouseclean.com</p>
                </div>
                <div className="bg-gray-800 p-6 rounded-lg">
                    <h2 className="text-2xl font-semibold">Text Us</h2>
                    <p className="mt-2">(725)-301-6814</p>
                </div>
                <div className="bg-gray-800 p-6 rounded-lg">
                    <h2 className="text-2xl font-semibold">Call us</h2>
                    <p className="mt-2">(725)-301-6814</p>
                </div>
            </div>
        </div>
    )
}

export default Methods
