import React from 'react'

function Awards() {
    const imageUrls = [
        '/img/awards/ACT.png',
        '/img/awards/LasVegas.png',
        '/img/awards/Family.png'
    ];

    return (
        <div className="bg-white w-full justify-center flex flex-col items-center pb-10">
            <h2 className="order-first w-full text-center my-10 fonst-size text-4xl font-bold">
                    Hightlights
            </h2>
            <div className="flex max-w-full flex-col items-center justify-center md:flex-row">
                {imageUrls.map((url, index) => (
                    <img key={index} src={url} alt={`award-${index}`} className="  h-60  md:h-24 md:max-lg:h-44 lg:h-52 xl:h-60 2xl:h-72 object-cover" />
                ))}
            </div>
        </div>
    );
}

export default Awards;
