import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useSource } from '../../../util/SourceContext';

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
  }
}

interface FormProps {
  preselectService?: string;
}

interface FormData {
  source: string;
  name: string;
  number: string;
  address: string;
  size: string;
  date: string;
  service: string;
  baseboards: boolean;
  fridgeInside: boolean;
  ovenInside: boolean;
  carpetShampooRooms: number;
  termsAccepted: boolean;
}

const Form: React.FC<FormProps> = ({ preselectService }) => {
  const source = useSource()
  const [formData, setFormData] = useState<FormData>({
    source: source,
    name: '',
    number: '',
    address: '',
    size: '',
    date: '',
    service: '',
    baseboards: false,
    fridgeInside: false,
    ovenInside: false,
    carpetShampooRooms: 0,
    termsAccepted: false
  });

  useEffect(() => {
    setFormData(prev => ({ ...prev, source }));
  }, [source]);

  const [submitted, setSubmitted] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    if (preselectService) {
      setFormData(prev => ({ ...prev, service: preselectService }));
    }
  }, [preselectService]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;
    if (type === 'checkbox') {
      const { checked } = e.target as HTMLInputElement;
      setFormData(prev => ({
        ...prev,
        [name]: checked
      }));
    } else if (type === 'number') {
      setFormData(prev => ({
        ...prev,
        [name]: parseInt(value, 10)
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
   
    e.preventDefault();
    if (!formData.termsAccepted) {
      alert("You must accept the terms of service before submitting the form.");
      return;
    }
    const price = await calculatePrice(formData);
    setTotalPrice(price);
    setSubmitted(true);
    const oldSize = formData.size;
    const oldService = formData.service;
    
    const sizeText = ['0 - 1000sqft', '1001 - 1500sqft', '1501 - 2000sqft', '2001 - 2500sqft', '2501 - 3000sqft', '3001 - 3500sqft', '3501 - 4000sqft', '4001 - 4500sqft', '4501 - 5000sqf'];
    formData.size = sizeText[parseInt(formData.size, 10)];
    
    switch (formData.service) {
      case 'S':
        formData.service = 'Standard Cleaning';
        break;
      case 'D':
        formData.service = 'Deep Cleaning';
        break;
      case 'M':
        formData.service = 'Move in/out';
        break;
      default:
        throw new Error(`Unknown service code: ${formData.service}`);
    }




    sendMessage({ ...formData, price });

    // Google Ads conversion tracking
    if (window.gtag) {
      window.gtag('event', 'conversion', {
        'send_to': 'AW-11414285835/PftvCJKp18MZEIvc4MIq',
        'event_callback': () => {
          console.log('Conversion tracked');
        }
      });
    }
    
    formData.service = oldService;
    formData.size = oldSize;
  };

  const handleServiceChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    handleChange(e);
    if (e.target.value === 'D' || e.target.value === 'M') {
      setFormData(prev => ({
        ...prev,
        fridgeInside: true,
        ovenInside: true
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        fridgeInside: false,
        ovenInside: false
      }));
    }
  };

  const calculatePrice = async (formData: FormData): Promise<number> => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/price`, { formData });
      return response.data.price as number;

    } catch (error) {
      console.error('Error:', error);
      return 0;
    }
  };

  const sendMessage = (leadInfo: any) => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/sendMessage`, { leadInfo })
      .catch(error => console.error('Error:', error));
  };

  return (
    <div className="flex items-start justify-center bg-white py-10">
      <div className="relative max-w-3xl w-full bg-white rounded-lg p-8 shadow-2xl mx-4">
        <header className="text-xl font-semibold text-gray-800">Online Quote</header>

        {!submitted ? (
          <form onSubmit={handleSubmit} className="relative mt-4">
            <div className="mt-8">
              <span className="block mb-2 text-lg font-medium text-gray-800">Personal Details</span>
              <div className="flex flex-wrap -mx-2">
                <div className="w-full md:w-1/3 px-2 mb-4">
                  <label className="block text-sm font-medium text-gray-700" htmlFor="name">Name:</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    placeholder="Enter First and Last name"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                  />
                </div>
                <div className="w-full md:w-1/3 px-2 mb-4">
                  <label className="block text-sm font-medium text-gray-700" htmlFor="number">Phone Number:</label>
                  <input
                    type="text"
                    id="number"
                    name="number"
                    value={formData.number}
                    onChange={handleChange}
                    required
                    placeholder="7255774523"
                    pattern="^[0-9]*$"
                    maxLength={10}
                    minLength={10}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                  />
                </div>
                <div className="w-full md:w-1/3 px-2 mb-4">
                  <label className="block text-sm font-medium text-gray-700" htmlFor="address">Address:</label>
                  <input
                    type="text"
                    id="address"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    required
                    placeholder="1020 This St"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                  />
                </div>
                <div className="w-full md:w-1/3 px-2 mb-4">
                  <label className="block text-sm font-medium text-gray-700" htmlFor="size">Size:</label>
                  <select
                    id="size"
                    name="size"
                    value={formData.size}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                  >
                    <option value="" disabled>Size in square feet</option>
                    <option value="0">0 - 1000sqft</option>
                    <option value="1">1001 - 1500sqft</option>
                    <option value="2">1501 - 2000sqft</option>
                    <option value="3">2001 - 2500sqft</option>
                    <option value="4">2501 - 3000sqft</option>
                    <option value="5">3001 - 3500sqft</option>
                    <option value="6">3501 - 4000sqft</option>
                    <option value="7">4001 - 4500sqft</option>
                    <option value="8">4501 - 5000sqft</option>
                  </select>
                </div>
                <div className="w-full md:w-1/3 px-2 mb-4">
                  <label className="block text-sm font-medium text-gray-700" htmlFor="date">Preferable date:</label>
                  <input
                    type="date"
                    id="date"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                  />
                </div>
                <div className="w-full md:w-1/3 px-2 mb-4">
                  <label className="block text-sm font-medium text-gray-700" htmlFor="service">Service:</label>
                  <select
                    id="service"
                    name="service"
                    value={formData.service}
                    onChange={handleServiceChange}
                    required
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                  >
                    <option value="" disabled>Type of Service</option>
                    <option value="S">Standard Cleaning</option>
                    <option value="D">Deep Cleaning</option>
                    <option value="M">Move in/out</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="mt-8">
              <span className="block mb-2 text-lg font-medium text-gray-800">Select Extras:</span>
              <div className="flex flex-wrap -mx-2">
                <div className="w-full md:w-1/3 px-2 mb-4 flex items-center">
                  <img src="https://media.istockphoto.com/id/1307239557/vector/floor-transition-moulding-vector-icon-design-editable-line-stroke.jpg?s=612x612&w=0&k=20&c=P24Y7VggU4xYVw_WPrZ-qaZtvN6TH8G1A3dfcTE01vI=" alt="Baseboards.png" className="w-12 h-12 mr-2" />
                  <label className="mr-2" htmlFor="baseboards">Baseboards</label>
                  <input
                    type="checkbox"
                    id="baseboards"
                    name="baseboards"
                    checked={formData.baseboards}
                    onChange={handleChange}
                    className="ml-2"
                  />
                </div>
                <div className="w-full md:w-1/3 px-2 mb-4 flex items-center">
                  <img src="https://cdn-icons-png.flaticon.com/512/1985/1985123.png" alt="Fridge.png" className="w-12 h-12 mr-2" />
                  <label className="mr-2" htmlFor="fridgeInside">Fridge Inside</label>
                  <input
                    type="checkbox"
                    id="fridgeInside"
                    name="fridgeInside"
                    checked={formData.fridgeInside}
                    onChange={handleChange}
                    className="ml-2"
                    disabled={formData.service === 'D' || formData.service === 'M'}
                  />
                </div>
                <div className="w-full md:w-1/3 px-2 mb-4 flex items-center">
                  <img src="https://cdn-icons-png.flaticon.com/512/1941/1941808.png" alt="Oven.png" className="w-12 h-12 mr-2" />
                  <label className="mr-2" htmlFor="ovenInside">Oven Inside</label>
                  <input
                    type="checkbox"
                    id="ovenInside"
                    name="ovenInside"
                    checked={formData.ovenInside}
                    onChange={handleChange}
                    className="ml-2"
                    disabled={formData.service === 'D' || formData.service === 'M'}
                  />
                </div>
                {/* <div className="w-full px-2 mb-4 flex items-center">
                  <img src="https://cdn-icons-png.flaticon.com/512/11058/11058836.png" alt="Carpet.png" className="h-12 mr-2" />
                  <label className="mr-2" htmlFor="carpetShampooRooms">Carpet Shampoo (Rooms)</label>
                  <input
                    type="number"
                    id="carpetShampooRooms"
                    name="carpetShampooRooms"
                    value={formData.carpetShampooRooms}
                    onChange={handleChange}
                    min="0"
                    max="20"
                    className="ml-4 mt-1 block w-[70px] border border-gray-max={} rounded-md shadow-sm p-2 text-center"
                  />
                </div> */}
              </div>
              <p className="text-sm text-gray-600 mt-2">
                Note: Stairways count as a room to be carpet washed.<br />
                Note: Service area within 20 miles of Las Vegas, NV.
              </p>
            </div>

            <div className="mt-8 flex items-center">
              <input
                type="checkbox"
                id="termsAccepted"
                name="termsAccepted"
                checked={formData.termsAccepted}
                onChange={handleChange}
                required
                className="mr-2"
              />
              <label htmlFor="termsAccepted" className="text-sm">
                I agree to the <Link to="/terms" className="text-blue-500 underline">terms of service</Link>.
              </label>
            </div>

            <button type="submit" className="mt-8 w-full md:w-auto px-4 py-2 bg-gray-800 text-white rounded-md shadow-md">
              Submit
            </button>
          </form>
        ) : (
          <div>
            <h2 className="text-2xl font-bold">Thank you!</h2>
            <p>Your quote request has been submitted, your quote for the job is $<span className="font-semibold">{totalPrice}</span> flat fee.</p>
            <p>We will contact you shortly to finish your appointment and take care of any additional requests.</p>
            <button onClick={() => setSubmitted(false)} className="mt-4 w-full md:w-auto px-4 py-2 bg-gray-800 text-white rounded-md shadow-md">
              Back
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Form;
